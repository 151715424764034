import React from 'react'
import styles from './Toc.module.css'

export default function Toc({ tableOfContents }: { tableOfContents: string }) {
  return (
    <aside
      className={styles.toc}
      dangerouslySetInnerHTML={{ __html: tableOfContents }}
    />
  )
}
