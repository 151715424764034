import React from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../Layout'
import Time from '../atoms/Time'
import Toc from '../atoms/Toc'
import Button from '../atoms/Button'
import styles from './PageMarkdown.module.css'

export default function PageTemplateMarkdown(props: PageProps) {
  const {
    html,
    frontmatter,
    fields,
    tableOfContents
  } = (props.data as any).markdownRemark
  const { title, description, toc, image } = frontmatter
  const isPressRelease = fields.slug.includes('/press')

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroSmall
      heroCenter
      heroLabel={
        isPressRelease && (
          <>
            <Link to="/press">Press Release</Link> — <Time date={fields.date} />
          </>
        )
      }
      contentNarrow
    >
      {toc && <Toc tableOfContents={tableOfContents} />}

      {isPressRelease && image && image.childImageSharp && (
        <Img fluid={image.childImageSharp.fluid} className={styles.teaser} />
      )}

      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: html }}
      />

      {isPressRelease && (
        <p style={{ marginTop: 'calc(var(--spacer) * 2)' }}>
          <Button style="text" to="/press">
            ← Back to Press
          </Button>
        </p>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageMarkdownBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        toc
        image {
          childImageSharp {
            fluid(maxWidth: 608) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      fields {
        slug
        date
      }
      tableOfContents(maxDepth: 3)
    }
  }
`
